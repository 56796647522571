body {
  font-family: "Inter", sans-serif;
  font-size: 1.5rem;
  overflow-x: hidden;
  background-color: lightgray;
}

.navbar {
  background-color: #582f74;
  font-size: 1.5rem;
}

.main-container {
  padding-top: 30px;
  padding-bottom: 8px;
}

#settings-icon,
#save-barcode-button {
  height: 3rem;
  width: 100%;
}

#btn-print {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.loading-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10000;
}

.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
.mw-name {
  min-width: 4.7rem;
  text-align: end;
  margin-inline-end: 1rem;
}
.mw-val {
  min-width: 3rem;
  text-align: center;
}

.svg-color {
  fill: #ffc107;
}

#print-element {
  max-width: 99vw;
}

@media only screen and (max-width: 992px) {
  #preview-border-area {
    margin-top: 0;
    transform: scale(0.9) translateY(-5%) !important;
  }

  #preview-gray-outer-area {
    overflow-y: hidden;
  }
}

@media only screen and (max-width: 768px) {
  #preview-border-area {
    margin-top: 0;
    transform: scale(0.67) !important;
  }

  #preview-gray-outer-area {
    height: 790px;
    overflow-y: hidden;
  }
}

@media only screen and (max-width: 576px) {
  #preview-border-area {
    margin-top: 0;
    transform: scale(0.5) !important;
  }

  #preview-gray-outer-area {
    height: 600px;
    overflow-y: hidden;
  }
}

@media print {
  body {
    background-color: unset !important;
    margin: 0;
    padding: 0;
    max-width: 100vw;
    max-height: 100vh;
  }

  #preview-gray-outer-area {
    place-items: unset !important;
    background-color: unset !important;
    width: unset !important;
    overflow-x: unset !important;
    height: 100%;
    width: 100%;
    border: 1px solid transparent;
    page-break-after: always;
  }

  #preview-border-area {
    background-color: white !important;
    border-color: unset !important;
    border-style: unset !important;
    border-width: unset !important;
    margin-bottom: unset !important;
    overflow-x: unset !important;
    height: 100%;
    width: 100%;
    display: table;
  }

  #pass-through-bg {
    height: 100%;
    width: 100%;
    display: table-cell;
  }
}

.hidden {
  display: none;
}

#parent-of-barcode-ref svg {
  padding: 0;
}
